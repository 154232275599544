import {
  Swiper,
  Navigation,
  Pagination,
  A11y,
  Autoplay,
  Thumbs,
  EffectFade,
} from 'swiper/swiper.esm.js';

// import lazyLoad from '../../components/lazy-load/lazy-load';

function mountCarousels(carouselEls) {
  const sliders = [];

  Swiper.use([Navigation, Pagination, A11y, Autoplay, Thumbs, EffectFade]);

  [...carouselEls].forEach((carouselEl, idx) => {
    const slides = carouselEl.querySelectorAll('.swiper-slide');
    const videos = carouselEl.querySelectorAll('.swiper-slide video');
    const swiperContainerEl = carouselEl.querySelector('.swiper-container');

    const swiper = new Swiper(swiperContainerEl, {
      // simulateTouch: false,
      speed: 500,
      loop: true,
      autoplay: {
        delay: 5000,
      },
      effect: 'fade',
      pagination: {
        el: document.querySelector(`.${carouselEl.id} .swiper-pagination`),
        type: 'bullets',
        clickable: true,
        bulletElement: 'button',
        renderBullet: (index, className) => (
          `<button class="${className}  block  text-2xl  mx-1  rounded-full  leading-1  transition  duration-150  ease-in-out"><span class="sr-only">${index + 1}</span></button>`
        ),
      },
      navigation: {
        nextEl: document.querySelector(`.${carouselEl.id} .swiper-button-next`),
        prevEl: document.querySelector(`.${carouselEl.id} .swiper-button-prev`),
      },
      a11y: {
        paginationBulletMessage: document.documentElement.lang === 'fi' ? 'Siirry diaan {{index}}' : 'Go to slide {{index}}',
      },
      // autoHeight: true,
      init: false,
    });

    swiper.blockId = carouselEl.id;

    swiper.on('slideChange', () => {
      // lazyLoad.refresh();
    });

    swiper.on('init', () => {

    });

    sliders[idx] = {
      swiper,
      slides,
      videos,
    };

    swiper.init();
  });
}

function init() {
  const carouselEls = document.querySelectorAll('.b-hero-carousel');
  if (carouselEls.length) {
    mountCarousels(carouselEls);
  }
}

export default {
  init,
};

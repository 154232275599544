/* eslint-env browser */

import lozad from 'lozad';

let observer;

function refresh() {
  observer.observe();
}

function init() {
  observer = lozad('.lazy-load', {
    rootMargin: '400px 0px',
    loaded: (el) => {
      el.classList.add('is--lazy-loaded');
    },
  });

  observer.observe();
}

export default {
  init,
  refresh,
};

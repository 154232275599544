import { createFocusTrap } from 'focus-trap';

class NavPanel {
  constructor({
    // Default options.
    panelEl = '.nav-panel',
    showEl = '[data-action="nav-panel-show"]',
    closeEl = '[data-action="nav-panel-close"]',
    toggleEl = '[data-action="nav-panel-toggle"]',
    backdropEl = '.nav-panel__backdrop',
    breakpoint = 960,
  } = {}) {
    this.panelEl = document.querySelector(panelEl);
    this.showEl = document.querySelector(showEl);
    this.closeEl = document.querySelector(closeEl);
    this.toggleEl = document.querySelector(toggleEl);
    this.backdropEl = document.querySelector(backdropEl);
    this.breakpointQuery = `(min-width: ${breakpoint}px)`;

    this.mql = window.matchMedia(this.breakpointQuery);

    this.focusTrap = createFocusTrap(this.panelEl, {
      clickOutsideDeactivates: true,
    });
  }

  show() {
    this.panelEl.setAttribute('aria-hidden', false);
    this.toggleEl.setAttribute('aria-expanded', true);
    document.body.classList.add('nav-panel--expanded');
  }

  close() {
    this.panelEl.setAttribute('aria-hidden', true);
    this.toggleEl.setAttribute('aria-expanded', false);
    document.body.classList.remove('nav-panel--expanded');
  }

  toggle() {
    if (this.panelEl.getAttribute('aria-hidden') === 'true') {
      this.show();
    } else {
      this.close();
    }
  }

  handleBreakpoint() {
    if (this.mql.matches) {
      this.close();
    }
  }

  registerEvents() {
    if (this.showEl) this.showEl.addEventListener('click', () => { this.show(); });
    if (this.closeEl) this.closeEl.addEventListener('click', () => { this.close(); });
    if (this.backdropEl) this.backdropEl.addEventListener('click', () => { this.close(); });
    if (this.toggleEl) this.toggleEl.addEventListener('click', () => { this.toggle(); });

    this.mql.addListener(() => { this.handleBreakpoint(); });

    document.addEventListener('keyup', (event) => {
      if (event.keyCode === 27) {
        this.close();
      }
    });

    this.panelEl.addEventListener('transitionend', (event) => {
      if (event.eventPhase === Event.AT_TARGET && event.propertyName === 'visibility') {
        if (event.target.getAttribute('aria-hidden') === 'true') {
          this.focusTrap.deactivate();
        } else {
          this.focusTrap.activate();
        }
      }
    });

    [...this.panelEl.querySelectorAll('a[href*="#"]')].forEach((el) => {
      el.addEventListener('click', () => {
        this.close();
      });
    });
  }

  mount() {
    if (this.panelEl) {
      this.registerEvents();
    }
  }
}

function init() {
  // TODO: This should be consistent with the breakpoint in CSS.
  new NavPanel({
    breakpoint: 960,
  }).mount();
}

export default {
  init,
};

/* eslint-env browser */
import inView from 'in-view';

function init() {
  const footstepEls = document.querySelector('.footsteps');

  if (!footstepEls) {
    return;
  }

  inView.offset(-50);

  inView('.footsteps').on('enter', (el) => {
    el.classList.add('in-view');
  });
}

export default {
  init,
};

/* eslint-env browser */
import * as basicScroll from 'basicscroll';

function setLineLengths(pineConePartEls) {
  pineConePartEls.forEach((el) => {
    el.style.setProperty('--stroke-length', el.getTotalLength());
  });
}

function init() {
  const pineConeEl = document.querySelector('.pinecone-animated');

  if (!pineConeEl) {
    return;
  }

  const parentBlockEl = pineConeEl.closest('.wp-block-group.oneoff--pinecone-animated');
  const breakpoint = window.matchMedia('(min-width: 601px)');
  const groupEls = pineConeEl.parentElement.nextElementSibling.querySelectorAll('.wp-block-group');
  const pineConePartEls = pineConeEl.querySelectorAll('polygon');

  try {
    setLineLengths(pineConePartEls);
    parentBlockEl.classList.add('is-initialized');
  } catch (error) {
    // MS Edge probably.
    console.warn('Browser SVG support lacking :(');
  }

  breakpoint.addListener(() => {
    setLineLengths(pineConePartEls);
  });

  groupEls.forEach((groupEl, index) => {
    const pineConePartEl = pineConePartEls[index];

    if (!pineConePartEl) {
      return;
    }

    let isAbove = false;
    const settings = {
      elem: groupEl,
      direct: parentBlockEl,
      // Format: <element>-<viewport>
      from: 'top-bottom',
      to: 'top-middle',
      props: {},
      inside: () => {
        if (isAbove) {
          isAbove = false;
          pineConePartEl.classList.remove('is-active');
          groupEl.classList.remove('is-above');
        }
      },
      outside: (instance, percentage) => {
        if (percentage > 100 && !isAbove) {
          isAbove = true;
          pineConePartEl.classList.add('is-active');
          groupEl.classList.add('is-above');
        } else if (percentage < 0 && isAbove) {
          isAbove = false;
          pineConePartEl.classList.remove('is-active');
          groupEl.classList.remove('is-above');
        }
      },
    };

    settings.props[`--visible-ratio-${index + 1}`] = {
      from: 0,
      to: 1,
    };

    const instance = basicScroll.create(settings);

    instance.start();
  });
}

export default {
  init,
};

// Modern Javascript file for new browsers.
// Build will include less polyfills etc than "legacy" version.

import 'focus-visible';
import 'element-qsa-scope';
// import 'alpinejs';

import objectFitImages from 'object-fit-images';
import smoothScrollPolyfill from 'smoothscroll-polyfill';

import navPanel from '../../components/nav-panel/nav-panel';
import topBar from '../../components/top-bar/top-bar';
import lazyLoad from '../../components/lazy-load/lazy-load';
import pineconeAnimated from '../../components/pinecone-animated/pinecone-animated';
import footsteps from '../../components/footsteps/footsteps';
import gravityForms from '../../components/gravity-forms/gravity-forms';
// import scrollAnimations from '../../components/scroll-animations/scroll-animations';
import carousels from '../../blocks/carousel/carousel';
import modifiabilityImage1 from '../../blocks/industry-fields-graphic/industry-fields-graphic';
import imageInfoPoints from '../../blocks/image-info-points/image-info-points';
import googleMap from '../../components/google-map/google-map';
import modelListFilters from '../../blocks/model-list-filters/model-list-filters';
import heroCarousel from '../../blocks/hero-carousel/hero-carousel';
import imageCarousel from '../../blocks/image-carousel/image-carousel';
// import { debounce } from 'lodash-es';

const appStart = () => {

  // Make WP-core jQuery available globally
  window.$ = window.jQuery;

  navPanel.init();
  topBar.init();
  lazyLoad.init();
  gravityForms.init();
  // scrollAnimations.init();
  carousels.init();
  pineconeAnimated.init();
  footsteps.init();
  modifiabilityImage1.init();
  imageInfoPoints.init();
  googleMap.init();
  modelListFilters.init();
  heroCarousel.init();
  imageCarousel.init();

  objectFitImages();
  smoothScrollPolyfill.polyfill();

  $('.lightbox a, a.lightbox').magnificPopup({
    type: 'image',
    gallery: {
      enabled: true,
    },
    removalDelay: 300,
    mainClass: 'mfp-fade',
  });

  // Gravity forms: workaround for wrongly placed required-asterisk, when label is hidden.
  $('.gfield_consent_label').each((index, el) => {
    $(el).next('.gfield_required').appendTo($(el));
  });

  // https://stackoverflow.com/questions/33606565/is-it-possible-to-calculate-the-viewport-width-vw-without-scrollbar
  document.documentElement.style.setProperty('--scrollbar-width', `${window.innerWidth - document.documentElement.clientWidth}px`);

  // Gravity forms: disable print broschure ordering for Collection
  function setPrintBroscureCheckbox() {
    if ($('#choice_6_21_2').is(':checked')) {
      $('#choice_6_33_2').prop('checked', false).prop('disabled', true);
    } else {
      $('#choice_6_33_2').prop('disabled', false);
    }
  }

  setPrintBroscureCheckbox();

  $('#choice_6_21_2').on('change', () => {
    setPrintBroscureCheckbox();
  });
};

// 'DOMContentLoaded' may fire before your script has a chance to run.
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', appStart);
} else {
  appStart();
}

import {
  Swiper,
  Navigation,
  Pagination,
  A11y,
  Autoplay,
  Thumbs,
} from 'swiper/swiper.esm';

import lazyLoad from '../../components/lazy-load/lazy-load';

function initThumbsCarousel(thumbsCarouselEl) {
  const swiperContainerEl = thumbsCarouselEl.querySelector('.swiper-container');
  const swiper = new Swiper(swiperContainerEl, {
    // simulateTouch: false,
    spaceBetween: 10,
    slidesPerView: 'auto',
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  });

  return swiper;
}

function mountCarousels(carouselEls) {
  const sliders = [];

  Swiper.use([Navigation, Pagination, A11y, Autoplay, Thumbs]);

  [...carouselEls].forEach((carouselEl, idx) => {
    const slides = carouselEl.querySelectorAll('.swiper-slide');
    const videos = carouselEl.querySelectorAll('.swiper-slide video');
    const swiperContainerEl = carouselEl.querySelector('.swiper-container');
    const thumbsCarouselEl = carouselEl.querySelector('.b-carousel-thumbs');
    const thumbsSwiper = thumbsCarouselEl && initThumbsCarousel(thumbsCarouselEl);

    const swiper = new Swiper(swiperContainerEl, {
      // simulateTouch: false,
      speed: 1000,
      loop: true,
      // autoplay: {
      //   delay: 10000,
      // },
      // pagination: {
      //   el: document.querySelectorAll(`.${swiperContainerEl.dataset.id} .swiper-pagination`),
      //   type: 'bullets',
      //   clickable: true,
      //   bulletElement: 'button',
      //   renderBullet: (index, className) => (
      //     `<button class="${className}  block  text-2xl  mx-1  rounded-full  leading-1  transition  duration-150  ease-in-out"><span class="sr-only">${index + 1}</span></button>`
      //   ),
      // },
      navigation: {
        nextEl: document.querySelector(`.${swiperContainerEl.dataset.id} .swiper-button-next`),
        prevEl: document.querySelector(`.${swiperContainerEl.dataset.id} .swiper-button-prev`),
      },
      a11y: {
        paginationBulletMessage: document.documentElement.lang === 'fi' ? 'Siirry diaan {{index}}' : 'Go to slide {{index}}',
      },
      // autoHeight: true,
      init: false,
      thumbs: {
        swiper: thumbsSwiper,
      },
    });

    swiper.blockId = swiperContainerEl.dataset.id;

    swiper.on('slideChange', () => {
      lazyLoad.refresh();
    });

    swiper.on('init', () => {

    });

    sliders[idx] = {
      swiper,
      slides,
      videos,
    };

    swiper.init();
  });
}

function init() {
  const carouselEls = document.querySelectorAll('.b-carousel');
  if (carouselEls.length) {
    mountCarousels(carouselEls);
  }
}

export default {
  init,
};

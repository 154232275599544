import tinysort from 'tinysort';
import { throttle } from 'lodash-es';

let blockEl;
let formEl;
// let sortFormEl;
let itemEls;
let noResultsMessageEl;
let sortOrderControlEl;

/**
 * Helper to reset a form field to its default (empty) state.
 */
function resetFormField(field) {
  if (field.tagName === 'TEXTAREA' || (field.tagName === 'INPUT' && ['text', 'number'].includes(field.type))) {
    field.value = field.defaultValue;
  } else if (field.tagName === 'INPUT' && ['radio', 'checkbox'].includes(field.type)) {
    field.checked = field.defaultChecked;
  } else if (field.tagName === 'SELECT') {
    [...field.children].forEach((option) => {
      option.selected = option.defaultSelected;
    });
  }
}


/**
 * Get FormData object from filter-form, with added fields from sort-form (if it exists).
 */
function getStateFromForm() {
  const formState = new FormData(formEl);

  // Combine Sort-form data to main form data
  // if (sortFormEl) {
  //   const sortFormState = new FormData(sortFormEl);
  //   for (const pair of sortFormState.entries()) {
  //     formState.append(pair[0], pair[1]);
  //   }
  // }

  return formState;
}


/**
 * Get filter criteria values from state, format them and give default values.
 * Default values basically allow all items to match, so that if no filter-selections are made,
 * all items are shown.
 */
function formatStateToSearchCriteria(state) {
  const filterNumberOfStoreys = state.getAll('floors[]');
  let filterLivingArea = state.get('area');
  let filterBedrooms = state.getAll('bedrooms[]');
  const filterTextSearch = state.get('text_search').split(' ');

  filterLivingArea = filterLivingArea ? filterLivingArea.split('_').map(value => Number(value)) : [0, Infinity];
  filterBedrooms = filterBedrooms.map(valuePair => valuePair.split('_').map(value => Number(value)));

  return {
    filterNumberOfStoreys,
    filterLivingArea,
    filterBedrooms,
    filterTextSearch,
  };
}


/**
 * Apply state to DOM. Update input values etc.
 */
function applyStateToForm(stateParams) {
  const state = new URLSearchParams(stateParams);
  const formControlEls = formEl.querySelectorAll('input, select');
  // const sortFormEls = sortFormEl ? sortFormEl.querySelectorAll('input, select') : [];
  // const allFormControlEls = [...formControlEls, ...sortFormEls];
  const allFormControlEls = [...formControlEls];

  // Loop through all form control elements and update their value from state.
  [...allFormControlEls].forEach((el) => {
    if (el.tagName === 'INPUT' && ['text', 'number', 'hidden'].includes(el.type)) {
      el.value = state.get(el.name);
    } else if (el.tagName === 'INPUT' && el.type === 'radio') {
      el.checked = state.get(el.name) === el.value;
    } else if (el.tagName === 'INPUT' && el.type === 'checkbox') {
      el.checked = state.getAll(el.name).includes(el.value);
    } else if (el.tagName === 'SELECT') {
      const options = state.getAll(el.name);
      const choicesInstance = window.choicesInstances && window.choicesInstances.get(el);
      if (choicesInstance) {
        // Select is using choices.js
        if (choicesInstance._isSelectMultipleElement) {
          // Removing values from multiselect doesn't work with just array of options.
          // Have to clear out all values and apply new ones.
          // choicesInstance.removeHighlightedItems(); // Why this doesn't work?
          const selectedValues = choicesInstance.getValue(true);
          selectedValues.forEach((value) => {
            choicesInstance.removeActiveItemsByValue(value);
          });
        }
        choicesInstance.setChoiceByValue(options);
      } else {
        [...el.children].forEach((optionEl) => {
          optionEl.selected = options.includes(optionEl.value);
        });
      }
    }
  });

}


/**
 * Compare item to search criterias.
 */
function matchItemToSearchCriteria(el, searchCriteria) {
  const {
    filterNumberOfStoreys,
    filterLivingArea,
    filterBedrooms,
    filterTextSearch,
  } = searchCriteria;

  const cardEl = el.firstElementChild;
  const itemNumberOfStoreys = cardEl.dataset.floors
    ? cardEl.dataset.floors.split(';')
    : [];
  const itemLivingArea = Number(cardEl.dataset.area);
  const itemBedrooms = Number(cardEl.dataset.bedrooms);
  const itemTitle = cardEl.dataset.title;

  // Storeys: Test by checking if arrays have intersecting values
  const matchNumberOfStoreys = !filterNumberOfStoreys.length || filterNumberOfStoreys.filter(value => itemNumberOfStoreys.includes(value)).length;

  // Living area: Test that item value is between filter min & max
  const matchLivingArea = filterLivingArea[0] <= itemLivingArea && itemLivingArea <= filterLivingArea[1];

  // Bedroom: Test that item itemBedroom count is between any min & max filter value
  const matchBedrooms = !filterBedrooms.length || filterBedrooms.some(([min, max]) => min <= itemBedrooms && itemBedrooms <= max);

  // Text search: Test that every keyword (separated by space) is included in the itemTitle
  const matchTextSearch = !filterTextSearch.length || filterTextSearch.every(term => itemTitle.toLowerCase().includes(term.toLowerCase()));

  return matchNumberOfStoreys && matchLivingArea && matchBedrooms && matchTextSearch;
}


/**
 * Update state to URL using History PushState.
 */
 function updateURLstate() {
  const formState = getStateFromForm();
  const stateParams = new URLSearchParams(formState).toString();
  window.history.pushState({ stateParams }, '', `?${stateParams}`);
}


/**
 * Handle filtering, do changed to DOM
 */
 function doFilter(shouldUpdateURL = true) {
  const formState = getStateFromForm();
  const searchCriteria = formatStateToSearchCriteria(formState);

  if (shouldUpdateURL) {
    updateURLstate();
  }

  // Hide all items initially
  [...itemEls].forEach(el => el.classList.add('hidden'));

  // Filter matching items
  const activeItems = [...itemEls].filter((el) => {
    return matchItemToSearchCriteria(el, searchCriteria);
  });

  // Show matching items
  activeItems.forEach(el => el.classList.remove('hidden'));

  // Toggle "No results" message
  noResultsMessageEl.classList.toggle('hidden', activeItems.length);
}


/**
 * Handle sorting, do changed to DOM
 */
function doSort(shouldUpdateURL = true) {
  const formState = getStateFromForm();
  const sortParam = formState.get('sort');

  let orderby = sortParam ? sortParam.split('_')[0] : '';
  const order = sortParam ? sortParam.split('_')[1] : 'asc';

  if (shouldUpdateURL) {
    updateURLstate();
  }

  if (orderby === 'title') {
    tinysort(itemEls,
      { selector: '.js-model-card', data: 'title', order },
    );
  } else if (orderby) {
    tinysort(itemEls,
      { selector: '.js-model-card', data: orderby, order, emptyEnd: order === 'asc' },
      { selector: '.js-model-card', data: 'title', order: 'asc' },
    );
  } else {
    tinysort(itemEls, { selector: '.js-model-card', data: 'initial-order', order: 'asc' },);
    // tinysort(itemEls, { natural: true, order: 'desc' });
  }
}


/**
 * Initialize everything.
 */
function init() {

  blockEl = document.querySelector('.model-list-filters');

  if (!blockEl) {
    return;
  }

  formEl = document.querySelector('.model-list-filters form');
  // sortFormEl = document.querySelector('.model-list__sort form');
  const formControlChoiceEls = formEl.querySelectorAll('input[type="radio"], input[type="checkbox"]');
  const formControlSelectEls = formEl.querySelectorAll('select');
  const formControlTextEls = formEl.querySelectorAll('input[type="text"], input[type="number"], input[type="hidden"]');
  noResultsMessageEl = document.querySelector('.model-list__no-results');
  itemEls = document.querySelectorAll('.model-list__item');

  const doFilterThrottled = throttle(doFilter, 500);

  /**
   * Register events
   */

  [...formControlChoiceEls].forEach((el) => {
    el.addEventListener('change', (event) => {
      if (event.isTrusted) {
        doFilterThrottled();
      }
    });
  });

  [...formControlSelectEls].forEach((el) => {
    el.addEventListener('change', (event) => {
      if (el.classList.contains('js-choice')) {
        doFilterThrottled();
      } else if (event.isTrusted) {
        doFilterThrottled();
      }
    });
  });

  [...formControlTextEls].forEach((el) => {
    el.addEventListener('keyup', (event) => {
      if (event.isTrusted) {
        doFilterThrottled();
      }
    });
  });

  // Register sorting events
  sortOrderControlEl = document.querySelector('.model-list-filters select[name="sort"]');
  const doSortThrottled = throttle(doSort, 500);

  // Save initial sort order values. Needed for resetting the sort order.
  itemEls.forEach((el, index) => {
    el.firstElementChild.setAttribute('initial-order', index);
  });

  if (sortOrderControlEl) {
    sortOrderControlEl.addEventListener('change', (event) => {
      if (sortOrderControlEl.classList.contains('js-choice')) {
        doSortThrottled();
      } else if (event.isTrusted) {
        doSortThrottled();
      }
    });
  }


  // Prevent submit
  formEl.addEventListener('submit', (event) => {
    event.preventDefault();
  });


  /**
   * Initial state
   */

  let initialStateParams = '';

  if (window.history && window.history.state) {
    initialStateParams = window.history.state.stateParams;
  } else if (window.location.search) {
    initialStateParams = window.location.search;
  }

  applyStateToForm(initialStateParams);
  doFilter(false);
  doSort(false);


  /**
   * Listen to history change (back/forward)
   */
  window.addEventListener('popstate', (event) => {
    applyStateToForm(event.state ? event.state.stateParams : '');
    doFilter(false);
    doSort(false);
  });

}

export default {
  init,
};

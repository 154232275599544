import {
  Swiper,
  Navigation,
  Pagination,
  A11y,
} from 'swiper/swiper.esm';

function init() {
  Swiper.use([Navigation, Pagination, A11y]);
  const carouselEls = document.querySelectorAll('.b-image-carousel');
  [...carouselEls].forEach((carouselEl, idx) => {
    const swiper = new Swiper(carouselEl.querySelector('.swiper-container'), {
      // freeMode: true,
      speed: 500,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        bulletElement: 'button',
      },
      navigation: {
        nextEl: carouselEl.querySelector('.swiper-button-next'),
        prevEl: carouselEl.querySelector('.swiper-button-prev'),
      },
      a11y: {
        paginationBulletMessage: document.documentElement.lang === 'fi' ? 'Siirry diaan {{index}}' : 'Go to slide {{index}}',
      },
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      // slidesPerView: 'auto',
      slidesPerView: 1,
      breakpoints: {
        480: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      },
    });
  });
}

export default {
  init,
};
